<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>User Query</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="field"
        label="Field"
        :disabled="fieldsSelect.id != -1"
        single-line
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="val"
        label="Value"
        single-line
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-combobox
        v-model="fieldsSelect"
        :items="$store.state.users.fields"
        item-value="id"
        item-text="value"
        hide-details
        label="Fields"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="operatorSelect"
        :items="operators"
        item-value="id"
        item-text="value"
        hide-details
        label="Fields"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="green" dark @click="addQuery" class="mb-2">Add Query</v-btn>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-combobox
        :items="userStatus"
        v-model="userReportSelect"
        item-value="key"
        item-text="value"
        hide-details
        label="Status"
      ></v-combobox>

      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="pageItemSelect"
        :items="pageItems"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Page"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
      <v-btn color="red ml-2" dark @click="blockDialog" class="mb-2"
        >Ban User</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="fieldHeaders"
      :options.sync="options"
      hide-default-footer
      hide-default-header
      :items="body"
      class="elevation-1"
    >
      <template v-slot:item.value="{ item }">
        <v-text-field
          v-model="item.value"
          label="Value"
          hide-details
          placeholder="value"
        ></v-text-field>
      </template>
      <template v-slot:item.operator="{ item }">
        <v-combobox
          v-model="item.operatorSelect"
          :items="operators"
          item-value="id"
          item-text="value"
          hide-details
          label="Fields"
        ></v-combobox>
      </template>
      <template v-slot:item.field="{ item }">
        <v-text-field
          v-if="item.fieldsSelect.id == -1"
          v-model="item.field"
          label="Field"
          hide-details
          placeholder="Field"
        ></v-text-field>
        <v-combobox
          v-if="item.fieldsSelect.id != -1"
          v-model="item.fieldsSelect"
          :items="$store.state.users.fields"
          item-value="id"
          item-text="value"
          hide-details
          label="Fields"
        ></v-combobox>
      </template>

      <template v-slot:item.action="{ item }">
        <v-icon medium color="red" class="mr-1" @click="removeQuery(item)"
          >link_off</v-icon
        >
      </template>
    </v-data-table>
    <v-data-table
      :headers="headers"
      :options.sync="options"
      hide-default-footer
      v-model="selected"
      show-select
      :items="purchases.data"
      :items-per-page="1000"
      class="elevation-1"
    >
      <template v-slot:item.status="{ item }">
        <div>
          {{ item.status | toUserStatus }}
        </div>
      </template>
      <template v-slot:item.deviceInfo="{ item }">
        <v-tooltip bottom>
          <template
            :allow-overflow="true"
            v-slot:activator="{ on }"
            :dark="true"
            color="#033"
          >
            <v-img
              width="25"
              v-on="on"
              style="float:left"
              :src="
                `https://pokerface.restplay.com/assets/${item.deviceInfo.os}.png`
              "
            />
            <v-img
              width="25"
              v-on="on"
              :src="
                `https://pokerface.restplay.com/assets/${item.platformInfo.snId}.png`
              "
            />
          </template>

          <span>
            <json-viewer
              name="df"
              :value="item.deviceInfo"
              :expand-depth="5"
              theme="my-awesome-json-theme"
              sort
            ></json-viewer>
            <json-viewer
              name="df"
              :value="item.platformInfo"
              :expand-depth="5"
              theme="my-awesome-json-theme"
              sort
            ></json-viewer>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.played="{ item }">
        <div
          v-for="(itemx, index) in item.played.p"
          :key="index"
          style="width:500px;"
        >
          <b class="yellow--text"> Date:</b> {{ itemx.d | formatDate }}
          <b class="yellow--text"> Amount:</b> {{ itemx.a | formatMoney }}
          <b class="yellow--text"> Hands:</b> {{ itemx.h | formatMoney }}
          <b class="yellow--text"> Time:</b> {{ itemx.t }}
        </div>
      </template>
      <template v-slot:item.playerOnline.l="{ item }">
        <div v-if="!item.playerOnline.o">
          <timeago
            :style="
              ` color: ${item.leftTime < 86400 * 3.1 ? 'orange' : '#ff7e7e'}`
            "
            locale="zh_TW"
            :datetime="new Date(item.playerOnline.l * 1000)"
          ></timeago>
        </div>
      </template>
      <template v-slot:item.playTime="{ item }">
        <div v-if="item">
          {{ item.playTime | getDateFormat }}
        </div>
      </template>
      <template v-slot:item.locationInfo.country_long="{ item }">
        <div style="display: flex;">
          <v-avatar size="36" style="margin-right:5px; margin-top:3px;">
            <v-img
              v-if="item && item.locationInfo"
              :src="
                `https://pokerface.restplay.com/flags/${item.locationInfo.country_short.toLocaleLowerCase()}.png`
              "
            />
          </v-avatar>
          <div v-if="item && item.deviceInfo.ipAdress">
            <span v-if="item.locationInfo">{{
              item.locationInfo.country_long
            }}</span>
            <div>{{ item.deviceInfo.ipAdress.split(",")[0] }}</div>
          </div>
        </div>
      </template>

      <template v-slot:item.chipAmount="{ item }">
        <div v-if="item">
          {{ item.chipAmount | formatMoney }}
        </div>
      </template>

      <template v-slot:item.registerDate="{ item }">
        <div>{{ new Date(item.registerDate * 1000) | formatDate }}</div>
      </template>
      <template v-slot:item.id="{ item }">
        <UserDetailCard
          :userId="item.id"
          :pictureUrl="item.pictureUrl"
          :userName="item.userName"
          :level="item.levelInfo.l"
          :flag="item.flag"
          :isOnline="item.playerOnline.o"
        />
      </template>
    </v-data-table>
    <v-dialog v-model="dialog1" max-width="1200px">
      <v-card>
        <v-card-title>
          <span class="headline">Ban User</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-toolbar flat>
              <v-toolbar-title>User Banned Detail</v-toolbar-title>
              <v-divider class="mx-2" inset vertical></v-divider>
              <v-combobox
                v-model="banUserReportSelect"
                :items="userStatus"
                :loading="$store.state.progress"
                item-value="key"
                item-text="value"
                hide-details
                label="User Status"
              ></v-combobox>

              <v-divider class="mx-2" inset vertical></v-divider>
              <v-combobox
                v-model="bannedReasonSelect"
                :items="bannedReason"
                :loading="$store.state.progress"
                item-value="id"
                item-text="value"
                hide-details
                label="Banned Reason"
              ></v-combobox>
              <v-divider class="mx-2" inset vertical></v-divider>
              <v-combobox
                v-model="timeleftSelect"
                :items="timelefts"
                :loading="$store.state.progress"
                item-value="id"
                v-if="banUserReportSelect.key == -1"
                item-text="value"
                hide-details
                label="Time Left"
              ></v-combobox>

              <v-divider class="mx-2" inset vertical></v-divider>
              <v-text-field
                v-model="timeleftManuel"
                label="Time Left"
                v-if="banUserReportSelect.key == -1"
                hide-details
                placeholder="seconds"
              ></v-text-field>
              <v-switch
                v-model="isChipDown"
                :label="`Is Chip Down`"
                hide-details
              ></v-switch>
              <v-divider class="mx-2" inset vertical></v-divider>

              <v-text-field
                v-model="chipAmount"
                label="Chip"
                :disabled="!isChipDown"
                hide-details
                placeholder="chip"
              ></v-text-field>
              <v-divider class="mx-2" inset vertical></v-divider>
              <v-btn color=" green" dark @click="userBanned()" class="mb-2"
                >User Ban</v-btn
              >
            </v-toolbar>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="userReportheaders"
                  :options.sync="options"
                  hide-default-footer
                  :items="selected"
                  :items-per-page="1000"
                  class="elevation-1"
                >
                  <template v-slot:item.status="{ item }">
                    <div>
                      {{ item.status | toUserStatus }}
                    </div>
                  </template>
                </v-data-table></v-col
              >
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog1 = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="userBanned">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        :total-visible="20"
        @input="pageClick"
        :length="purchases.totalPage"
      ></v-pagination>
    </div>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="1600px">
        <v-card>
          <v-card-title>
            <span class="headline">User Profile</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="$store.state.purchases.detail.id"
                    label="Legal first name*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="$store.state.purchases.detail.id"
                    label="Id"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="$store.state.purchases.detail.chip"
                    label="Chip"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="$store.state.purchases.detail.price"
                    label="Price"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-if="$store.state.purchases.detail"
                    v-model="$store.state.purchases.detail.id"
                    label="userId"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-if="$store.state.purchases.detail"
                    v-model="$store.state.purchases.detail.userName"
                    label="userName"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <json-viewer
              name="df"
              :value="$store.state.purchases.detail.data"
              :expand-depth="5"
              theme="my-awesome-json-theme"
              sort
            ></json-viewer>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import userStatus from "@/lib/userStatus";
import bannedReason from "@/lib/bannedReason";
export default {
  name: "UserQueryCard",

  components: {
    UserDetailCard: () => import("@/components/Users/UserDetailCard.vue"),
  },

  props: {
    clicked: {
      type: Boolean,
      default: false,
    },
    show: {
      type: String,
      default: "",
      description: "show",
    },
    val: {
      type: String,
      default: "",
      description: "UserId",
    },
    field: {
      type: String,
      default: "",
      description: "field",
    },
  },
  data() {
    return {
      dialog: false,
      dialog1: false,
      currentPage: 1,
      purchases: {},
      selected: [],

      options: {},
      fieldsSelect: { id: -1, value: "All" },
      operatorSelect: { id: 0, value: "Equals" },
      fields: [
        { id: -1, value: "All" },
        {
          id: "user.deviceInfo.deviceUniqueIdentifier",
          value: "user.deviceInfo.deviceUniqueIdentifier",
        },
        { id: "user.deviceInfo.ipAdress", value: "user.deviceInfo.ipAdress" },
      ],
      operators: [
        { id: 0, value: "Equals" },
        {
          id: 1,
          value: "Gte",
        },
        { id: 2, value: "Lte" },
      ],
      headers: [
        { text: "Block User", value: "blockUser", sortable: false },

        {
          text: "Id",
          value: "id",
        },
        { text: "Platform", value: "deviceInfo" },

        {
          text: "DeviceUniqueIdentifier",
          value: "deviceInfo.deviceUniqueIdentifier",
        },
        { text: "Chip Amount", value: "chipAmount" },
        { text: "Play Time", value: "playTime" },

        { text: "Created Date", value: "registerDate" },
        { text: "Location Info", value: "locationInfo.country_long" },
        { text: "Status", value: "status" },
        { text: "Sig", value: "deviceInfo.sig" },

        { text: "Last Online", value: "playerOnline.l" },

        { text: "Profit", value: "played" },
      ],
      fieldHeaders: [
        { text: "Field", value: "field", sortable: false },

        {
          text: "value",
          value: "value",
        },
        {
          text: "operator",
          value: "operator",
        },
        { text: "Actions", value: "action", sortable: false },
      ],
      userReportheaders: [
        {
          text: "Id",
          value: "id",
        },

        {
          text: "DeviceUniqueIdentifier",
          value: "deviceInfo.deviceUniqueIdentifier",
        },
        { text: "Chip Amount", value: "chipAmount" },
        { text: "Play Time", value: "playTime" },

        { text: "Created Date", value: "registerDate" },
        { text: "Location Info", value: "locationInfo.country_long" },
        { text: "Status", value: "status" },
      ],
      chipAmount: 0,
      isChipDown: false,
      timeleftSelect: null,
      timeleftManuel: null,
      loaded: false,
      timelefts: [
        { value: "10 Minutes", key: 60 * 10 },
        { value: "1 Hours", key: 60 * 60 },
        { value: "4 Hours", key: 60 * 60 * 4 },
        { value: "12 Hours", key: 60 * 60 * 12 },
        { value: "24 Hours", key: 60 * 60 * 24 },
        { value: "3 Days", key: 60 * 60 * 24 * 3 },
        { value: "7 Days", key: 60 * 60 * 24 * 7 },
        { value: "14 Days", key: 60 * 60 * 24 * 14 },
        { value: "1 Months", key: 60 * 60 * 24 * 30 },
        { value: "3 Months", key: 60 * 60 * 24 * 30 * 3 },
      ],
      bannedReason: bannedReason,
      userStatus: userStatus,
      user: null,
      body: [],
      userReportSelect: { key: 0, value: "Active" },
      banUserReportSelect: { key: 0, value: "Active" },
      bannedReasonSelect: { key: -1, value: "All" },
      pageItemSelect: { id: 50, value: "50" },
      pageItems: [
        { id: 50, value: "50" },
        { id: 100, value: "100" },
        { id: 250, value: "250" },
        { id: 500, value: "500" },
        { id: 1000, value: "1000" },
        { id: 2000, value: "2000" },
        { id: 5000, value: "5000" },
        { id: 10000, value: "10000" },
      ],
    };
  },
  watch: {
    // async val() {
    //   await this.initialize();
    // },
    async show() {
      this.body = [];
      this.addQuery();
      await this.initialize();
    },
    options: {
      handler() {
        if (this.loaded) return;
        this.loaded = true;
        this.addQuery();
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
    async initialize() {
      this.selected = [];
      var a = [];
      this.body.forEach((m) =>
        a.push({
          field: m.field || m.fieldsSelect.id,
          operator: m.operatorSelect.id,
          value: m.value,
        })
      );
      localStorage.setItem("userQueryParams", JSON.stringify(this.body));
      this.purchases = await this.$store.dispatch("users/postAll", {
        page: this.currentPage - 1,
        options: this.options,
        status: this.userReportSelect.key,
        body: a,
        take: this.pageItemSelect.id,
      });
    },
    addQuery() {
      if (this.currentQuery) {
        this.body = this.body.filter((m) => m.id != this.currentQuery.id);
      }
      if (
        this.body.filter((m) => m.field == this.field && m.value == this.val)
          .length == 0
      )
        this.body.push({
          id: new Date(),
          field: this.field,
          fieldsSelect: this.fieldsSelect,
          value: this.val,
          operator: this.operatorSelect.id,
          operatorSelect: this.operatorSelect,
        });
      localStorage.setItem("userQueryParams", JSON.stringify(this.body));
    },
    removeQuery(item) {
      this.body = this.body.filter((m) => m.id != item.id);
      localStorage.setItem("userQueryParams", JSON.stringify(this.body));
    },
    editQuery(item) {
      this.currentQuery = item;
      this.fieldsSelect = item.fieldsSelect;
      this.val = item.value;
      this.operatorSelect = item.operatorSelect;
    },
    async detail(item) {
      await this.$store.dispatch("purchases/get", item.id);
      this.dialog = true;
    },
    async blockDialog() {
      this.dialog1 = true;
    },
    async blockUser(item) {
      await this.$store.dispatch("pictureUploads/approvePicture", item.id);
      // this.$store.dispatch("pictureUploads/getAll", { page: 0 });
    },
    async allBlockUser() {
      this.selected.forEach(async (m) => {
        await this.$store.dispatch("pictureUploads/approvePicture", m.id);
      });
      // this.$store.dispatch("pictureUploads/getAll", { page: 0 });
    },
    async userBanned() {
      await this.$store.dispatch("userReports/bulkUserBan", {
        ids: this.selected.map((m) => m.id),
        status: this.banUserReportSelect.key,
        reasonCode: this.bannedReasonSelect.key,
        isChipDown: this.isChipDown,
        timeLeft:
          this.timeleftManuel || this.timeleftSelect
            ? parseInt(
                this.timeleftManuel
                  ? this.timeleftManuel
                  : this.timeleftSelect.key
              )
            : 0,
        chipAmount: parseInt(this.chipAmount),
      });
      this.dialog1 = false;
      this.$store.dispatch("setAlert");
      this.initialize();
    },
  },
  async mounted() {
    this.body = JSON.parse(localStorage.getItem("userQueryParams")) || [];
    await this.$store.dispatch("users/getUserFields");
  },
};
</script>
